<script lang="ts" setup>
import AppStore from './reusables/AppStore.vue'
import GooglePlay from './reusables/GooglePlay.vue'
import { UiButton } from '@ui'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

const breakpoints = useBreakpoints({
  desktop: 1280,
})
const route = useRoute()
const router = useRouter()

const { t, locale } = useI18n()
const localeRoute = useLocaleRoute()
const {
  public: { appLink },
} = useRuntimeConfig()

const props = defineProps<{ isHide?: boolean }>()

const isShow = ref(false)
const isShowClass = ref(false)
const isHideMenu = ref(false)
router.afterEach(() => {
  isShow.value = false
  updateOverflow(false)
})

watch(
  () => route.name,
  (value) => {
    if (
      value === 'creator-pass' ||
      value === 'founder-pass' ||
      value === 'nft-tickets'
    ) {
      isHideMenu.value = true
    } else {
      isHideMenu.value = false
    }
  },
  { immediate: true }
)

const updateOverflow = (value: boolean) => {
  if (value) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.removeAttribute('style')
  }
}

if (process.client) {
  updateOverflow(breakpoints.desktop.value)
  watch(
    () => breakpoints.desktop.value,
    (value) => {
      if (value) {
        isShow.value = false
        if (!isShow.value) {
          updateOverflow(false)
        } else {
          updateOverflow(value)
        }
      } else {
        if (value) {
          updateOverflow(false)
        }
      }
    },
    { immediate: true }
  )
}

const setOpen = () => {
  isShow.value = !isShow.value

  if (!isShow.value) {
    isShowClass.value = true
    updateOverflow(false)
  } else {
    isShowClass.value = false
    updateOverflow(true)
  }
}
const dropdowns = [
  {
    title: 'Solutions',
    links: [
      { id: 'solutions-1', href: '/token-gating', name: 'Token Gating' },
      {
        id: 'solutions-2',
        href: '/mighty-networks-alternative',
        name: 'Manage Communities',
      },
      { id: 'solutions-3', href: '/nft-tickets', name: 'Sell NFT Tickets' },
      {
        id: 'solutions-4',
        href: '/paid-content-nft',
        name: 'Gate Paid Content',
      },
      {
        id: 'solutions-5',
        href: 'https://app.belong.net',
        name: 'Manage Calendars',
      },
      { id: 'solutions-6', href: '/founder-pass', name: 'Founder pass' },
      { id: 'solutions-7', href: '/creator-pass', name: 'Creator pass' },
      { id: 'solutions-8', href: '/ai', name: 'Ai Agents' },
    ],
  },
  {
    title: 'Industries & Cases',
    links: [
      { id: 'industries-1', href: '/blog', name: 'Blog' },
      { id: 'industries-2', href: '/hubs', name: 'Hubs' },
      { id: 'industries-3', href: '/events', name: 'Events' },
      { id: 'industries-4', href: '/gaming', name: 'Gaming' },
    ],
  },
  {
    title: 'Developers',
    links: [
      {
        id: 'developers-1',
        href: 'https://docs.belong.net/',
        name: 'API Docs',
      },
      {
        id: 'developers-2',
        href: 'https://belongnet.github.io/sdk/',
        name: 'Belong SDK',
      },
      {
        id: 'developers-3',
        href: 'https://belong.betteruptime.com/',
        name: 'Service Status',
      },
    ],
  },
  {
    title: 'Socials',
    links: [
      {
        id: 'socials-1',
        href: 'https://twitter.com/belongnet',
        name: 'i-belong:x',
      },
      {
        id: 'socials-2',
        href: 'https://www.youtube.com/channel/UCvEYurLCqhm6mY3qJ39-BNg',
        name: 'i-belong:youtube',
      },
      {
        id: 'socials-3',
        href: 'https://discord.gg/QzwrUXXWCA',
        name: 'i-belong:discord',
      },
      {
        id: 'socials-4',
        href: 'https://zealy.io/cw/belongnet/questboard',
        name: 'i-belong:zealy',
      },
    ],
  },
]

const activeDropdown = ref<{
  title: string
  links: { id: number; href: string; name: string }[]
} | null>(null)

const activateDropdown = (title: string) => {
  const dropdown = dropdowns.find((d) => d.title === title)
  if (dropdown) {
    activeDropdown.value = dropdown
  }
}

const deactivateDropdown = () => {
  activeDropdown.value = null
}
</script>

<template>
  <header>
    <div
      class="header mt-5"
      :class="{ 'xl:bg-transparent': route.path === '/gaming' }"
    >
      <div
        class="max-w-285 mx-auto hidden rounded-full h-13.5 px-2 xl:flex xl:flex-row xl:justify-between"
        style="
          background: linear-gradient(
            148.42deg,
            rgba(13, 13, 13, 0.12) -18.27%,
            rgba(13, 13, 13, 0.4) 80.96%
          );
        "
      >
        <NuxtLink :to="localeRoute('/')" class="flex">
          <img
            alt="Belong"
            src="https://content.belong.net/p/content/logo_with_text_bff58d6a84.svg"
            class="min-w-31 w-31 h-13.5"
          />
        </NuxtLink>
        <div class="flex items-center w-201 justify-center gap-3.5">
          <UiDropDown
            v-for="(dropdown, index) in dropdowns.slice(0, 3)"
            :key="index"
            :links="dropdown.links"
          >
            <template #title> {{ dropdown.title }} </template>
          </UiDropDown>

          <Menu as="div" class="relative inline-block" v-slot="{ open, close }">
            <MenuButton
              id="menu-button-discord"
              class="flex items-center gap-1.5 justify-center h-full w-full py-4 px-2.5 text-base text-white"
              :disabled="open"
              aria-label="Discord"
            >
              <div class="relative">
                <i class="i-belong:x w-6.5 h-6.75 opacity-60" />

                <i
                  class="i-belong:plus-icon w-3 h-3 absolute bottom-0.5 -right-1.5"
                />
              </div>
            </MenuButton>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform opacity-0"
            >
              <MenuItems
                class="absolute -left-0.5 -top-0.75 mt-2.5 rounded-5 bg-[#16161699] overflow-hidden focus:outline-none"
                style="backdrop-filter: blur(15px)"
              >
                <MenuItem
                  v-for="link in dropdowns.find(
                    (item) => item.title === 'Socials'
                  )?.links"
                  :key="link.id"
                  v-slot="{ active }"
                >
                  <NuxtLink :to="link.href" target="_blank">
                    <button
                      :class="[
                        active
                          ? 'bg-[#161616] opacity-100'
                          : 'text-white opacity-60',
                        ' flex w-full items-center px-3 py-2.25 text-base',
                      ]"
                    >
                      <i
                        :class="`${link.name} w-6.5 h-6.75 bg-contain bg-center`"
                      />
                    </button>
                  </NuxtLink>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>

        <div class="flex flex-row items-center gap-2.5">
          <NuxtLink
            :to="localeRoute('/token')"
            class="h-9.5 text-white rounded-full flex items-center p-0.25 uppercase whitespace-pre"
            style="
              background: linear-gradient(
                85.11deg,
                #7540ee -2.65%,
                #4467ff 47.09%,
                #ff8fe9 93.65%
              );
            "
          >
            <div
              class="bg-[#000000] rounded-full flex flex-row items-center px-3 h-full text-sm font-500"
            >
              $long Token
            </div>
          </NuxtLink>

          <NuxtLink :to="appLink" target="_blank" class="launch-app">
            <UiButton
              variant="default"
              class="!border-none !h-9.5 !px-4"
              style="
                background: linear-gradient(
                  107.92deg,
                  #4d61fd 37.78%,
                  #bb2bbf 106.06%
                );
              "
            >
              <p class="font-500 text-sm text-white">
                {{ t('web-app') }}
              </p>
            </UiButton></NuxtLink
          >
        </div>
      </div>

      <Transition name="fade">
        <div
          v-if="!isShow"
          class="absolute right-0 left-0 z-4 top-0 p-4 left-0 w-full xl:top-9 xl:hidden"
        >
          <div
            class="flex flex-row items-center justify-between w-full xl:ml-26 mr-15"
            :class="{ '!justify-end': isHideMenu }"
          >
            <NuxtLink v-if="!isHideMenu" :to="localeRoute('/')">
              <img
                :alt="t('belong')"
                class="h-13"
                src="~/assets/images/home/logo-belong.svg"
              />
            </NuxtLink>
            <div class="flex">
              <div
                class="flex items-start justify-center flex-col h-9.75 bg-black border-1 border-solid border-[#515151] rounded-full pl-4 pr-4.5 z-2 ml-4.5 lx:ml-0 cursor-pointer"
                @click="setOpen()"
              >
                <div
                  :class="{
                    'menu-line-1-show': isShow,
                    'menu-line-1-hide': isShowClass,
                  }"
                  class="menu-line-1"
                />
                <div
                  :class="{
                    'menu-line-2-show': isShow,
                    'menu-line-2-hide': isShowClass,
                  }"
                  class="menu-line-2"
                />
                <div
                  :class="{
                    'menu-line-3-show': isShow,
                    'menu-line-3-hide': isShowClass,
                  }"
                  class="menu-line-3"
                />
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition name="fade">
        <div
          v-if="isShow"
          class="flex flex-col fixed right-0 top-0 z-3 h-full bottom-0 max-w-full w-full xl:hidden"
          style="
            backdrop-filter: blur(9px);
            background: linear-gradient(
              180deg,
              rgba(21, 21, 21, 0.9) 0%,
              rgba(0, 0, 0, 0.9) 100%
            );
          "
        >
          <div class="bg-[#151515] p-4 w-full">
            <div
              class="flex flex-row items-center justify-between w-full"
              :class="{ '!justify-end': isHideMenu }"
            >
              <NuxtLink v-if="!isHideMenu" :to="localeRoute('/')">
                <img
                  :alt="t('belong')"
                  class="h-13"
                  src="~/assets/images/home/logo-belong.svg"
                />
              </NuxtLink>
              <div class="flex">
                <div
                  class="flex items-start justify-center flex-col h-9.75 bg-black border-1 border-solid border-[#515151] rounded-full pl-4 pr-4.5 z-2 ml-4.5 cursor-pointer"
                  @click="setOpen()"
                >
                  <div
                    :class="{
                      'menu-line-1-show': isShow,
                      'menu-line-1-hide': isShowClass,
                    }"
                    class="menu-line-1"
                  />
                  <div
                    :class="{
                      'menu-line-2-show': isShow,
                      'menu-line-2-hide': isShowClass,
                    }"
                    class="menu-line-2"
                  />
                  <div
                    :class="{
                      'menu-line-3-show': isShow,
                      'menu-line-3-hide': isShowClass,
                    }"
                    class="menu-line-3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col overflow-y-auto h-full pb-11.5">
            <div>
              <Transition name="fade" mode="out-in">
                <div v-if="!activeDropdown">
                  <div
                    v-for="dropdown in dropdowns.slice(0, 3)"
                    :key="dropdown.title"
                    class="py-4 cursor-pointer px-5 flex flex-row justify-between items-center text-white text-opacity-60"
                    @click="activateDropdown(dropdown.title)"
                  >
                    <p class="text-5">
                      {{ dropdown.title }}
                    </p>

                    <i class="i-belong:arrow w-1.75 h-1.25 -rotate-90" />
                  </div>
                </div>

                <div v-else>
                  <div
                    class="py-4 cursor-pointer px-5 flex flex-row gap-4 items-center"
                    @click="deactivateDropdown"
                  >
                    <i class="i-belong:chevron-forward w-2 h-3.25" />
                    <p class="text-5 text-white text-opacity-60">Back</p>
                  </div>
                  <ul>
                    <li
                      v-for="link in activeDropdown.links"
                      :key="link.id"
                      class="py-4 cursor-pointer px-5 flex flex-row justify-between items-center"
                    >
                      <a :href="link.href" class="text-5 !text-opacity-60"
                        >{{ link.name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </Transition>
            </div>

            <Transition name="fade" mode="out-in">
              <div v-if="!activeDropdown">
                <div
                  class="flex flex-row justify-between mt-3.5 w-full px-5 sm:px-0 max-w-97 mx-auto"
                >
                  <template
                    v-for="link in dropdowns.find(
                      (item) => item.title === 'Socials'
                    )?.links"
                    :key="link.id"
                  >
                    <NuxtLink :to="link.href" target="_blank">
                      <i
                        :class="`${link.name} w-9.5 h-9.75 bg-contain bg-center opacity-60 hover:opacity-100`"
                      />
                    </NuxtLink>
                  </template>
                </div>
                <div class="mt-8 mx-5 flex flex-col gap-6">
                  <div class="flex flex-col gap-6 sm:flex-row sm:gap-3.5">
                    <NuxtLink
                      :to="localeRoute('/token')"
                      class="w-full text-center font-500 text-base text-[#CB99FF] hover:text-[#FFB0F0] bg-[#30203D] rounded-full px-5 py-2.5 uppercase whitespace-pre"
                    >
                      $long Token
                    </NuxtLink>

                    <NuxtLink :to="appLink" target="_blank" class="w-full">
                      <UiButton
                        variant="default"
                        class="bg-white !border-none !h-11 !px-4"
                      >
                        <p class="font-500 text-black">
                          {{ t('web-app') }}
                        </p>
                      </UiButton></NuxtLink
                    >
                  </div>

                  <NuxtLink
                    to="https://docs.belong.net/"
                    target="_blank"
                    class="w-full"
                  >
                    <UiButton
                      variant="default"
                      class="bg-white !border-none !h-11 !px-4"
                    >
                      <p
                        class="font-500 text-black flex flex-row items-center justify-center"
                      >
                        Developer Docs
                        <i class="i-belong:chevron-up-right w-7.75 h-7.5" />
                      </p>
                    </UiButton>
                  </NuxtLink>

                  <div
                    class="flex flex-row gap-5 justify-center flex-wrap max-w-97 mx-auto min-[375px]:flex-nowrap"
                  >
                    <AppStore class="!h-10.5 w-full" />
                    <GooglePlay class="!h-10.5 w-full" />
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </Transition>
    </div>
  </header>
</template>

<style scoped>
header {
  @apply absolute w-full z-101;
}

.menu-line-1,
.menu-line-2,
.menu-line-3 {
  @apply h-0.25 bg-[#FFB0F0];
}

.menu-line-1,
.menu-line-3 {
  @apply w-5.75;
}

.menu-line-2 {
  @apply w-4.5 my-1.75;
}

.menu-line-1-show {
  animation: 0.3s show ease-in-out both;
}

.menu-line-2-show {
  animation: 0.3s hide ease-in-out both;
}

.menu-line-3-show {
  animation: 0.3s show-3 ease-in-out both;
}

@keyframes show {
  0% {
  }

  50% {
  }

  100% {
    transform: translate(1px, 9px) rotate(45deg);
  }
}

@keyframes show-3 {
  0% {
  }

  50% {
  }

  100% {
    transform: translate(1px, -7px) rotate(-45deg);
  }
}

@keyframes hide {
  0% {
  }

  50% {
    transform: translateX(30px);
    opacity: 0;
  }

  100% {
    transform: translateX(30px);
    opacity: 0;
  }
}

.menu-line-1-hide {
  animation: 0.3s hide-1 ease-in-out both;
}

.menu-line-2-hide {
  animation: 0.3s hide-2 ease-in-out both;
}

.menu-line-3-hide {
  animation: 0.3s hide-3 ease-in-out both;
}

@keyframes hide-1 {
  0% {
    transform: translate(1px, 9px) rotate(45deg);
  }
  50% {
  }

  100% {
  }
}

@keyframes hide-2 {
  0% {
    transform: translateX(30px);
    opacity: 0;
  }

  50% {
  }

  100% {
  }
}

@keyframes hide-3 {
  0% {
    transform: translate(1px, -7px) rotate(-45deg);
  }

  50% {
  }

  100% {
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.menu-link {
  @apply text-[1.25rem] text-opacity-60 w-full text-center hover:text-white;
}
</style>
