<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

const props = defineProps<{
  links: { id: number; name: string; href: string }[]
}>()

const localeRoute = useLocaleRoute()

function isUrlSimple(str: string): boolean {
  return str.startsWith('http://') || str.startsWith('https://')
}

const ids = computed(() => props.links.map((link) => link.id))
const menuButtonId = computed(() => `dropdown-${ids.value[0]}`)
</script>

<template>
  <Menu as="div" class="relative inline-block h-full" v-slot="{ open }">
    <MenuButton
      :id="menuButtonId"
      class="flex items-center justify-center gap-1.5 h-full w-full justify-center px-2.5 text-3.5 text-white whitespace-pre"
      :class="[open ? 'text-opacity-100' : 'text-opacity-60']"
    >
      <slot name="title" />
      <i
        class="i-belong:arrow w-1.75 h-1.25 transition-transform"
        :class="{ 'rotate-180': open }"
      />
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute left-0 mt-2.5 w-84 rounded-5 bg-[#16161699] overflow-hidden focus:outline-none"
        style="backdrop-filter: blur(15px)"
      >
        <MenuItem
          v-for="link in links"
          :key="link.href"
          v-slot="{ active, close }"
          :disabled="!link.href"
        >
          <div>
            <NuxtLink
              :class="[
                active ? 'bg-[#161616] text-opacity-100' : ' !text-opacity-60',
                'flex w-full items-center px-4 py-2.25 text-base ',
              ]"
              :to="
                link.href
                  ? isUrlSimple(link.href)
                    ? link.href
                    : localeRoute(link.href)
                  : ''
              "
              :target="isUrlSimple(link.href) ? ' _blank' : '_self'"
              @click="close"
            >
              {{ link.name }}
            </NuxtLink>
          </div>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>
